import handleHamburgerClick from './globalsHamburger';

/**
 * This function initializes all modules that belongs to all pages
 *
 * @function
 */
function initGlobals() {
    handleHamburgerClick();
}

initGlobals();