export default function handleHamburgerClick() {
    const body = document.querySelector("[data-body]");
    const burgerTopnav = document.querySelector("[data-topnav]");
    const hamburger = document.querySelector("[data-hamburger]");

    hamburger.addEventListener("click", () => {
        if (hamburger.dataset.state === "opened") {
            hamburger.dataset.state = "";
            burgerTopnav.dataset.state = "";
            body.classList.remove("prevent-scroll");
        } else {
            hamburger.dataset.state = "opened";
            burgerTopnav.dataset.state = "opened";
            body.classList.add("prevent-scroll");
        }
    });
}
